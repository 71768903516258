import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { connect } from "react-redux"
import loadable from "@loadable/component"

import { setLocation } from "../actions/setLocation"
import SuspenseLoading from "../components/SuspenseLoading"

const AnnouncementPage = loadable(
  () => import("../components/Announcements/AnnouncementPage"),
  { fallback: <SuspenseLoading /> }
)

const Announcements = ({ dispatch }) => {
  //Set the location.
  useEffect(() => {
    dispatch(setLocation("/announcements"))
  }, [dispatch])

  return (
    <>
      <Router basepath="/announcements">
        <AnnouncementPage path=":announcementHandle" />
      </Router>
    </>
  )
}

export default connect()(Announcements)
